import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

export const ButtonVariants = {
	Default:
		'bg-white inline-flex items-center rounded-lg py-4 px-4 md:px-6 text-sm md:text-lg transition-colors font-family-centra-book focus:outline-offset-0 focus:outline focus:outline-1',
	/* TODO: This currently fails contrast checks, need to think how best to handle */
	Inactive: 'bg-blue-200 text-blue-300',
	Answer: 'shadow-answer color-brand-blue-400 hover:bg-brand-pale-400 hover:color-blue-400 focus:shadow-answer-focus focus:outline-answer-focus',
	AnswerSelected:
		'answer-selected bg-blue-500 text-white hover:bg-brand-blue-400 focus:shadow-answer-selected-focus focus:outline-answer-selected-focus',
	Business:
		'rounded-sm border-1 border-blue-200 bg-white px-4 py-5 flow-vertical text-left w-full',
	BusinessSelected:
		'rounded-sm border-1 border-blue-200 bg-brand-pale-400 px-4 py-5 flow-vertical text-left w-full',
	White: 'border-2 bg-white border-blue-400 text-blue-400 hover:text-white hover:bg-blue-400 focus:shadow-answer-focus focus:outline-answer-focus',
	Ghost: 'bg-transparent border-2 border-white text-white hover:text-blue-400 hover:bg-white focus:shadow-answer-focus focus:outline-answer-focus',
	Main: 'bg-blue-400 text-white hover:bg-brand-blue-500 focus:shadow-answer-focus focus:outline-answer-focus',
	Link: 'bg-transparent !border-b-1 !rounded-none !p-0',
};

const Button = ({
	children,
	type,
	onClick,
	className,
	variant,
	...buttonAttributes
}) => (
	<button
		className={clsx(
			ButtonVariants.Default,
			ButtonVariants[variant],
			className
		)}
		{...buttonAttributes}
		type={type} // eslint-disable-line react/button-has-type
		onClick={onClick}
	>
		{children}
	</button>
);

Button.defaultProps = {
	type: 'button',
	onClick: () => {},
	className: '',
	variant: '',
};

Button.propTypes = {
	children: PropTypes.node.isRequired,
	type: PropTypes.string,
	variant: PropTypes.string,
	onClick: PropTypes.func,
	className: PropTypes.string,
};

export { Button };
