import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { AccordionGroup } from '../components/molecules/accordion-group';
import { NextSteps } from '../components/molecules/next-steps';
import { OverflowScroll } from '../components/molecules/overflow-scroll';
import { Button } from '../components/atoms/button';
import { RadioButton } from '../components/atoms/radio-button';
import { CheckboxButton } from '../components/atoms/checkbox-button';
import { TextInput } from '../components/atoms/text-input';
import { Textarea } from '../components/atoms/textarea';
import { CloseButton } from '../components/atoms/close-button';
import { ReferenceCode } from '../components/atoms/reference-code';
import { NextStep } from '../components/atoms/next-step';
import { Accordion } from '../components/atoms/accordion';
import { Advisor } from '../components/atoms/advisor';

import VideoIcon from '../assets/video-call.svg';
import BrewIcon from '../assets/brew.svg';
import TelephoneIcon from '../assets/telephone.svg';

import { getIcon } from '../utils/score-icon';

const Advisors = [
	<Advisor
		key="advisor1"
		name="Katherine Blakeley CIPD"
		pronouns="she/her"
		jobTitle="Employment Law and HR Consultant"
		image={
			<StaticImage
				src="../images/portrait.jpg"
				alt="Woman wearing black scoop-neck long-sleeved shirt"
				className="w-[78px] h-[78px] md:w-[125px] md:h-[125px] rounded-[100%] isolate"
				imgClassName="object-[50%_80%]"
			/>
		}
	/>,
	<Advisor
		key="advisor2"
		name="Katherine Blakeley CIPD"
		pronouns="she/her"
		jobTitle="Employment Law and HR Consultant"
		image={
			<StaticImage
				src="../images/portrait.jpg"
				alt="Woman wearing black scoop-neck long-sleeved shirt"
				className="w-[78px] h-[78px] md:w-[125px] md:h-[125px] rounded-[100%] isolate"
				imgClassName="object-[50%_80%]"
			/>
		}
	/>,
];

// markup
const ComponentsPage = () => (
	<main id="main" className="container px-4 mx-auto">
		<h1 className="mb-6 text-3xl">Triage Component Library</h1>

		<section className="mb-6">
			<h2 className="mb-6 text-2xl">Button</h2>

			<Button className="px-4 py-2 border-2 border-black">
				Test button
			</Button>
		</section>

		<section className="mb-6">
			<h2 className="mb-6 text-2xl">Form elements</h2>

			<form>
				<fieldset className="mb-6">
					<legend className="text-xl">Radio</legend>

					<div className="flex flex-wrap gap-1">
						<RadioButton
							name="radio_button_test"
							labelText="Radio button one"
							value="first"
							id="radio_button_one"
						/>
						<RadioButton
							name="radio_button_test"
							labelText="Radio button two"
							value="second"
							id="radio_button_two"
						/>
						<RadioButton
							name="radio_button_test"
							labelText="Radio button three"
							value="third"
							id="radio_button_three"
						/>
						<RadioButton
							name="radio_button_test"
							labelText="Radio button four"
							value="fourth"
							id="radio_button_four"
						/>
					</div>
				</fieldset>

				<fieldset className="mb-6">
					<legend className="text-xl">Checkbox</legend>

					<CheckboxButton
						name="checkbox_button_test"
						labelText="Checkbox button one"
						value="first"
						id="checkbox_button_one"
					/>
					<CheckboxButton
						name="checkbox_button_test"
						labelText="Checkbox button two"
						value="second"
						id="checkbox_button_two"
					/>
					<CheckboxButton
						name="checkbox_button_test"
						labelText="Checkbox button three"
						value="third"
						id="checkbox_button_three"
					/>
					<CheckboxButton
						name="checkbox_button_test"
						labelText="Checkbox button four"
						value="fourth"
						id="checkbox_button_four"
					/>
				</fieldset>

				<fieldset className="mb-6">
					<legend className="text-xl">Text input</legend>

					<TextInput
						id="test_input"
						name="test_input"
						labelText="Test input"
					/>

					<TextInput
						id="test_input_two"
						name="test_input_two"
						labelText="Test input with placeholder"
						placeholder="Placeholder here"
					/>

					<TextInput
						id="test_input_three"
						name="test_input_three"
						labelText="Test input with hint"
						hintText="This one has some hint text"
					/>
				</fieldset>

				<fieldset className="mb-6">
					<legend className="text-xl">Textarea</legend>

					<Textarea
						id="test_textarea"
						name="test_textarea"
						labelText="Test textarea"
						rows={5}
					/>

					<Textarea
						id="test_textarea_two"
						name="test_textarea_two"
						labelText="Test textarea with placeholder"
						rows={5}
						placeholder="Placeholder here"
					/>

					<Textarea
						id="test_textarea_three"
						name="test_textarea_three"
						labelText="Test textarea with hint"
						rows={5}
						hintText="This one has some hint text"
						aria-required="true"
					/>
				</fieldset>

				<Button className="px-4 py-2 border-2 border-black">
					Next button to knowhere
				</Button>
			</form>
		</section>

		<section className="mb-6">
			<h2 className="mb-6 text-2xl">Close button</h2>
			<CloseButton
				destination="https://www.peninsulagrouplimited.com/"
				id="componentsClose"
			>
				Close
			</CloseButton>
		</section>

		<section className="mb-6">
			<h2 className="mb-6 text-2xl">Reference code</h2>
			<ReferenceCode readableReference="test-1234" />
		</section>

		<section className="mb-6">
			<h2 className="mb-6 text-2xl">Next steps</h2>
			<NextSteps
				steps={[
					<NextStep
						title="Arrange a video call with one of our advisors"
						text="tomorrow or day after"
						icon={<VideoIcon />}
						key="step1"
						to="/"
					/>,
					<NextStep
						title="Arrange a face to face meeting at a location that suits you"
						text="tomorrow or day after"
						icon={<BrewIcon />}
						key="step2"
						to="/"
					/>,
					<NextStep
						title="Give us a call"
						text="No robots, just people"
						icon={<TelephoneIcon />}
						key="step3"
						to="/"
					/>,
				]}
			/>
		</section>

		<section className="mb-6">
			<h2 className="mb-6 text-2xl">Single accordion</h2>
			<Accordion
				title="Lorem ipsum dolor sit amet"
				buttonId="accordionHead"
				contentId="accordionContent"
				icon={getIcon(0.8)}
			>
				<p>
					Orci varius natoque penatibus et magnis dis parturient
					montes, nascetur ridiculus mus. Aliquam erat volutpat.
					rutrum sed lacus ac fermentum.
				</p>
			</Accordion>
		</section>

		<section className="mb-6">
			<h2 className="mb-6 text-2xl">Accordion group</h2>
			<AccordionGroup
				title="Accordion title"
				accordions={[
					<Accordion
						key="accordion1"
						title="Accordion 1"
						buttonId="accordionHead1"
						contentId="accordionContent1"
						icon={getIcon(0.8)}
					>
						<p>
							Orci varius natoque penatibus et magnis dis
							parturient montes, nascetur ridiculus mus. volutpat.
							Fusce rutrum sed lacus ac fermentum.
						</p>
					</Accordion>,
					<Accordion
						key="accordion2"
						title="Accordion 2"
						buttonId="accordionHead2"
						contentId="accordionContent2"
						icon={getIcon(0.5)}
					>
						<p>
							Orci varius natoque penatibus et magnis dis
							parturient montes, nascetur ridiculus mus. volutpat.{' '}
							<a style={{ textDecoration: 'underline' }} href="/">
								Fusce rutrum
							</a>{' '}
							sed lacus ac fermentum.
						</p>
					</Accordion>,
					<Accordion
						key="accordion3"
						title="Accordion 3"
						buttonId="accordionHead3"
						contentId="accordionContent3"
						icon={getIcon(0.3)}
					>
						<>
							<p>
								Orci varius natoque penatibus et magnis dis
								parturient montes, nascetur ridiculus mus.
							</p>
							<p>
								Aliquam erat volutpat. Fusce rutrum sed lacus ac
								fermentum.
							</p>
							<p>
								Donec lobortis nulla sed bibendum ornare.at diam
								tempor ultrices et vitae justo.
							</p>
							<p>
								Phasellus quis bibendum leo, ac blandit elit.
								Suspendisse posuere ex vitae feugiat consequat.
							</p>
						</>
					</Accordion>,
				]}
			/>
		</section>

		<section className="mb-8">
			<h2 className="mb-6 text-2xl">Advisor</h2>
			<Advisor
				name="Katherine Blakeley CIPD"
				pronouns="she/her"
				jobTitle="Employment Law and HR Consultant"
				image={
					<StaticImage
						src="../images/portrait.jpg"
						alt="Woman wearing black scoop-neck long-sleeved shirt"
						className="w-[78px] h-[78px] md:w-[125px] md:h-[125px] rounded-[100%] isolate"
						imgClassName="object-[50%_80%]"
					/>
				}
			/>
		</section>

		<section className="mb-6">
			<h2 className="mb-6 text-2xl">Overflow scroll</h2>
			<OverflowScroll
				title="You’ll be chatting with one of our HR experts below"
				items={Advisors}
				className="mb-8"
				borderBetween
				centreDesktop
			/>
		</section>
	</main>
);

export default ComponentsPage;
