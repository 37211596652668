import React from 'react';
import PropTypes from 'prop-types';

export const AccordionGroup = ({ title, accordions }) => (
	<section>
		{title ? <h2>{title}</h2> : null}
		<ul>
			{accordions.map((accordion) => (
				<li key={accordion.key}>{accordion}</li>
			))}
		</ul>
	</section>
);

AccordionGroup.defaultProps = {
	title: '',
};

AccordionGroup.propTypes = {
	title: PropTypes.string,
	accordions: PropTypes.arrayOf(PropTypes.node).isRequired,
};
