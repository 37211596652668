import React from 'react';
import PropTypes from 'prop-types';

const CheckboxButton = ({
	labelText,
	onChange,
	id,
	name,
	...inputAttributes
}) => (
	<>
		<label htmlFor={id}>{labelText}</label>
		<input
			type="checkbox"
			onChange={onChange}
			id={id}
			name={name}
			{...inputAttributes}
		/>
	</>
);

CheckboxButton.defaultProps = {
	onChange: () => {},
};

CheckboxButton.propTypes = {
	labelText: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

export { CheckboxButton };
